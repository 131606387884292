<template>
  <div class="hello">
    <div class="menu">
      <router-link to="/" class="menu-item" tag="div">Home</router-link>
      <router-link to="/over" class="menu-item" tag="div">Over</router-link>
      <div class="menu-item menu-center">
        Logo hier???
      </div>
      <router-link to="/literatuur" class="menu-item" tag="div">Literaire werken</router-link>
      <router-link to="/contact" class="menu-item" tag="div">Contact</router-link>
    </div>
<!--    <div>-->
<!--      <form ref="form" @submit.prevent="sendEmail">-->
<!--        <label>Name</label>-->
<!--        <input type="text" name="user_name">-->
<!--        <label>Email</label>-->
<!--        <input type="email" name="user_email">-->
<!--        <label>Message</label>-->
<!--        <textarea name="message"></textarea>-->
<!--        <input type="submit" value="Send">-->
<!--      </form>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<script>
import emailjs from '@emailjs/browser';

export default {
  methods: {
    sendEmail() {
      emailjs.sendForm('service_w1wvwz7', 'template_s1dotrm', this.$refs.form, 'user_ezc7hvycmiyiYbmLPs5I3')
          .then((result) => {
            console.log('SUCCESS!', result.text);
          }, (error) => {
            console.log('FAILED...', error.text);
          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin: 20px;
    background: white;
    border-radius: 20px;
    border: solid #cfc161;
  }

  .menu-item {
    flex: 1;
    text-align: center;
    font-size: 20px;
    color: #707070;
  }

  .menu-item:hover {
    cursor: pointer;
    opacity: 80%;
  }

  .menu-center {
    font-size: 36px;
    color: #dddddd;

  }

</style>
