<template>
  <div class="hello">
    <div class="menu">
      <router-link to="/" class="menu-item" tag="div">Home</router-link>
      <router-link to="/over" class="menu-item" tag="div">Over</router-link>
      <div class="menu-item menu-center">
        Logo hier???
      </div>
      <router-link to="/literatuur" class="menu-item" tag="div">Literaire werken</router-link>
      <router-link to="/contact" class="menu-item" tag="div">Contact</router-link>
    </div>

    <div class="information_panel">
      <div class="informatie_tekst">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<br><br>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </p>
     </div>
      <router-link to="/contact" class="contact_button home_button" tag="div">Neem direct contact op!</router-link>
      <router-link to="/over" class="meerweten_button home_button" tag="div">Meer weten?</router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin: 20px;
    background: white;
    border-radius: 20px;
    border: solid #cfc161;
  }

  .menu-item {
    flex: 1;
    text-align: center;
    font-size: 20px;
    color: #707070;
  }

  .menu-center {
    font-size: 36px;
    color: #dddddd;

  }

  .menu-item:hover {
    cursor: pointer;
    opacity: 80%;
  }

  .information_panel {
    width: 550px;
    height: 600px;
    display: flex;
    background: white;
    position: relative;
    margin-top: 50px;
    left: 60%;
    border-radius: 20px;
    border: solid #cfc161;
  }

  .informatie_tekst {
    width:450px;
    height:400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    display: flex;
    color: black;
  }

  .contact_button {
    padding-left: 25px;
    padding-right: 25px;
    height: 50px;
    display: flex;
    position: absolute;
    background: #cfc161;
    border-radius: 10px;
    left: 10%;
    top: 85%;
    align-items: center;
    text-align:center;
  }

  .meerweten_button {
    padding-left: 25px;
    padding-right: 25px;
    height: 50px;
    display: flex;
    position: absolute;
    background: #cfc161;
    border-radius: 10px;
    right:10%;
    top: 85%;
    text-align:center;
    align-items: center;
  }

  .home_button:hover {
    cursor: pointer;
    opacity: 80%;
  }
</style>
