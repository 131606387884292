import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Contact from '../components/Contact.vue'
import Literatuur from '../components/Literatuur.vue'
import Over from '../components/Over.vue'


export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/literatuur',
            name: 'Literatuur',
            component: Literatuur
        },
        {
            path: '/over',
            name: 'Over',
            component: Over
        }
    ]
})