<template>
  <div class="hello">
    <div class="menu">
      <router-link to="/" class="menu-item" tag="div">Home</router-link>
      <router-link to="/over" class="menu-item" tag="div">Over</router-link>
      <div class="menu-item menu-center">
        Logo hier???
      </div>
      <router-link to="/literatuur" class="menu-item" tag="div">Literaire werken</router-link>
      <router-link to="/contact" class="menu-item" tag="div">Contact</router-link>
    </div>
    <div>
      <div class="contact_box">
        <form ref="form" @submit.prevent="sendEmail">
          <label>Naam</label>
          <input type="text" name="user_name">
          <label>Email-adres</label>
          <input type="email" name="user_email">
          <label>Telefoon nummer</label>
          <input type="text" name="phone">
          <label>Bericht</label>
          <textarea name="message"></textarea>
          <input type="submit" value="Stuur">
        </form>
      </div>
    </div>
    <div class="contact_details">
      <p class="contact_informatie">Geert van Uythoven<br>geert@gvuinterimadvies.nl</p>
      <a href="https://www.linkedin.com/in/geert-van-uythoven-3a271198" class="fa fa-linkedin"></a>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<script>
import emailjs from '@emailjs/browser';

export default {
  methods: {
    sendEmail() {
      emailjs.sendForm('service_w1wvwz7', 'template_s1dotrm', this.$refs.form, 'user_ezc7hvycmiyiYbmLPs5I3')
          .then((result) => {
            console.log('SUCCESS!', result.text);
          }, (error) => {
            console.log('FAILED...', error.text);
          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin: 20px;
    background: white;
    border-radius: 20px;
    border: solid #cfc161;
  }

  .menu-item {
    flex: 1;
    text-align: center;
    font-size: 20px;
    color: #707070;
  }

  .menu-item:hover {
    cursor: pointer;
    opacity: 80%;
  }

  .menu-center {
    font-size: 36px;
    color: #dddddd;

  }

  .contact_box {
    width: 50vw;
    height: 60vh;
    position: absolute;
    left: 10%;
    display: flex;
    margin-top: 10vh;
    background: white;
    border-radius: 20px;
    border: solid #cfc161;
    color: black;
  }

  input[type=text], input[type=email], textarea{
    display: flex;
    width: 45vw;
    margin-top: 5px;
    margin-left:45px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
  }

  textarea {
    height: 10vh;
  }

  label {
    margin-top: 5px;
    margin-left:45px;
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  input[type=submit] {
    margin-top: 5px;
    background-color: #cfc161;
    color: white;
    font-size: 12pt;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }

  .contact_details {
    width: 25vw;
    height: 15vh;
    position: absolute;
    right: 10%;
    display: flex;
    margin-top: 10vh;
    background: #cfc161;
    border-radius: 20px;
    border: solid #cfc161;
    color: black;
    align-items: center;
  }

  .fa {
    height: 10px;
    width: 10px;
    margin-top: 10px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
  }

  .fa-linkedin {
    color: white;
    background-image: url('~@/assets/linkedin.png');
    background-size: 30px;
  }

  .contact_informatie {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15pt;
  }


</style>


